import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useOrganizationContext } from '../../contexts/OrganizationContext.jsx';
import { useUserContext } from '../../contexts/UserContext.jsx';
import Loading from '../../components/Loading.jsx';
import { useLocation, useNavigate } from "react-router-dom";
import posthog from 'posthog-js';

const AnalyticsContent = () => {

  const { state } = useLocation();
  const navigate = useNavigate();
  const { getMbJwt } = useOrganizationContext();
  const { currentOrganization, currentPermissions } = useUserContext();
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeLoading, setIframeLoading] = useState(true);
  const analyticsOrigin = import.meta.env.VITE_ANALYTICS_ORIGIN;
  const defaultParams = new URLSearchParams({
    logo: 'false',
    top_nav: 'true',
    search: 'true',
    new_button: 'true',
  });

  useEffect(() => {
    posthog.capture('$pageview');
  }, []);

  useEffect(() => {
    const callback = (event) => {
      const metabaseEvent = event?.data?.metabase;
      document.body.style.overflow = 'hidden';

      if (metabaseEvent && metabaseEvent.type === 'location' && metabaseEvent.location?.pathname !== '/') {
        let newIframeUrl = `${metabaseEvent.location.pathname}${metabaseEvent.location.search}${metabaseEvent.location.hash}`;

        // don't save questions
        if (!metabaseEvent.location.pathname.includes('question')) {
          console.log(newIframeUrl);
          if (newIframeUrl?.includes('collection')) {
            newIframeUrl = newIframeUrl.split('?')[0];
          }
          console.log(newIframeUrl);
          sessionStorage.setItem('returnTo', newIframeUrl);
        }
      }
    };

    window.addEventListener('message', callback);

    const getIframeUrl = async () => {
      if (!analyticsOrigin) {
        return;
      }
      const jwt = await getMbJwt();
      if (!jwt) {
        return;
      }
      let finalIframeUrl = '';
      const savedIframeUrl = state?.link ? state.link : sessionStorage.getItem('returnTo');

      if (savedIframeUrl) {
        const returnTo = savedIframeUrl.includes('?') ? `${savedIframeUrl}&${defaultParams}` : `${savedIframeUrl}?${defaultParams}`;
        finalIframeUrl = `${analyticsOrigin}/auth/sso?jwt=${jwt}&return_to=${encodeURIComponent(returnTo)}`;
      } else {
        const returnTo = `/?${defaultParams}`;
        finalIframeUrl = `${analyticsOrigin}/auth/sso?jwt=${jwt}&return_to=${encodeURIComponent(returnTo)}`;
        sessionStorage.setItem('returnTo', '/');
      }
      setIframeUrl(finalIframeUrl);
    }

    if (currentOrganization) {
      getIframeUrl();
    }

    if (currentOrganization && !currentPermissions.includes('fe__analytics__view')) {
      navigate('/home');
    }
    return () => {
      window.removeEventListener('message', callback);
      document.body.style.overflow = 'auto';
    };
  }, [currentOrganization]);

  return (
    <>
      {!iframeUrl ? <Loading /> :
        <>
          {iframeLoading ? <Loading /> : null}
          <iframe
            src={iframeUrl}
            width="100%"
            style={{ height: '92vh', display: iframeLoading ? 'none' : 'block' }}
            onLoad={() => setIframeLoading(false)}
          ></iframe>
        </>
      }
    </>
  );
};

export default function Analytics() {
  return (
    <AnalyticsContent />
  );
}
