import { Link, useParams, useNavigate } from "react-router-dom";
import { buttonStyle } from '../libs/sharedStyles.js';
import { primaryButtonClasses } from '../libs/sharedClassNames.js';
import { getEnrichedConnection, getConnectionInfo, getOrganizationProducts, getOrganizationConsumerProducts } from '../libs/fetch.js';

import { integrationIds } from '../data/integrations.js';
import { useUserContext } from "../contexts/UserContext.jsx";

import Loading from '../components/Loading.jsx';
import NotFoundPage from "./404.jsx";

import { Suspense, lazy, useEffect, useState } from 'react';

const integrationComponents = {
  AmazonSellerCentral: lazy(() => import('../pages/integrations/amazonSellerCentral.jsx')),
  AmazonVendorCentral: lazy(() => import('../pages/integrations/amazonVendorCentral.jsx')),
  CostcoSmallVendor: lazy(() => import('../pages/integrations/costcoSmallVendor.jsx')),
  Faire: lazy(() => import('../pages/integrations/faire.jsx')),
  Kehe: lazy(() => import('../pages/integrations/kehe.jsx')),
  Kroger: lazy(() => import('../pages/integrations/kroger.jsx')),
  SPINSStoreInsights: lazy(() => import('../pages/integrations/spinsStoreInsights.jsx')),
  Target: lazy(() => import('../pages/integrations/target.jsx')),
  MyUNFI: lazy(() => import('../pages/integrations/unfiInsights.jsx')),
  VIP: lazy(() => import('../pages/integrations/vip.jsx')),
  Walmart: lazy(() => import('../pages/integrations/walmart.jsx')),
  Wegmans: lazy(() => import('../pages/integrations/wegmans.jsx')),
  WholeFoodsSRP: lazy(() => import('../pages/integrations/wholeFoodsSRP.jsx')),
  Shopify: lazy(() => import('../pages/integrations/shopify.jsx')),
  Thrive: lazy(() => import('../pages/integrations/thrive.jsx')),
  Doras: lazy(() => import('../pages/integrations/doras.jsx')),
};

export default function Integration() {
  const { currentOrganization } = useUserContext();
  const { integrationId } = useParams();
  const [integration, setIntegration] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchConnection = async () => {
      const connection = await getEnrichedConnection(currentOrganization, integrationId);
      if (connection) {
        const [data_sources, products, consumerProducts] = await Promise.all([
          getConnectionInfo(connection.connection_id),
          getOrganizationProducts(currentOrganization, integrationId),
          getOrganizationConsumerProducts(currentOrganization)
        ]);
        setIntegration({ ...connection, data_sources, products, consumerProducts });
      } else {
        setIntegration({ component: 'NotFoundPage' });
      }
    };

    if (integrationIds.includes(Number(integrationId)) && currentOrganization) {
      fetchConnection();
    } else if (!integrationIds.includes(Number(integrationId)) && currentOrganization) {
      navigate('/home');
    }
  }, [currentOrganization, integrationId]);

  if (!integration) {
    return <Loading />;
  }

  const IntegrationComponent = integrationComponents[integration.component] || NotFoundPage;

  return (
    <Suspense fallback={<Loading />}>
      <div className="mx-auto max-w-[100rem] mb-10">
        <IntegrationComponent state={integration} />
        <div className="mt-10 mx-auto sm:px-6 lg:px-8">
          <Link to='/integrations'>
            <button style={buttonStyle} className={primaryButtonClasses}>&larr; Back to Integrations</button>
          </Link>
        </div>
      </div>
    </Suspense>
  );
}

