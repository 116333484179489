import React from 'react';
import { Link } from "react-router-dom";
import {
  PencilSquareIcon,
  BuildingOfficeIcon,
  EyeIcon
} from '@heroicons/react/20/solid';

import StatusBadge from './StatusBadge.jsx';
import { supabase } from '../libs/api.js';
import Loading from './Loading.jsx';

const GridLists = ({ integrations }) => {

  if (!integrations || integrations.length === 0) {
    return <Loading />;
  }

  const renderContent = (connection) => {
    if (connection.connection_status === "active") {
      return (
        <>
          <div className="flex w-0 flex-1">
            <Link
              to={`/integrations/${connection.integration_id}`}
              state={{ tab: 'Status' }}
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
            >

              <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              Details
            </Link>
          </div>
          <div className="flex w-0 flex-1">
            <Link
              to={`/integrations/${connection.integration_id}`}
              state={{ tab: 'Products' }}
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
            >
              <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              Products
            </Link>
          </div>
        </>
      );
    } else if (connection.connection_status === "configuring") {
      return (
        <div className="flex w-0 flex-1">
          <Link
            to={`/integrations/${connection.integration_id}`}
            state={{ tab: 'Status' }}
            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
          >
            <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            Edit
          </Link>
        </div>
      );

    } else if (connection.is_available) {
      return (
        <div className="flex w-0 flex-1">
          <Link
            to={`/integrations/${connection.integration_id}`}
            state={{ tab: 'Credentials' }}
            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
          >
            <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            Set Up Integration
          </Link>
        </div>
      )

    }
  }

  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {integrations.sort((a,b) => a.name.localeCompare(b.name)).map((connection) => (
        <li key={connection.name} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between p-6">
            <div className="flex-1">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">{connection.name}</h3>
              </div>
              <StatusBadge status={connection.connection_status} />
              <p className="mt-1 text-sm text-gray-500">{connection.title}</p>
            </div>
            {connection.imageUrl ? (
              <img className="h-[6rem] w-[6rem] flex-shrink-0 rounded-full bg-gray-100" src={connection.imageUrl} alt="" />
            ) : (
                <BuildingOfficeIcon className="h-[6rem] w-[6rem] text-gray-200" aria-hidden="true" />
              )}
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              {renderContent(connection)}
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default GridLists;
